<template>
  <div class="activity-wrapper">
    <div v-if="activityData">
      <div class="_banner" :class="'_banner_link_'+btnLinks.length">
        <img :src="activityData.head_img">
        <span v-for="(item, index) of btnLinks" :class="'_btn_link _btn_link_'+(index+1)"
              @click="onClickPageLink(item)"></span>
      </div>
      <div class="_list" style="text-align: center" v-if="activityData.jump_link_url.substr(0,3)=='gh_'">
        <wx-open-launch-weapp
          id="launch-btn"
          :username=activityData.jump_link_url
          path=""
        >
          <script type="text/wxtag-template">
            <style>.btn {
              text-align: center;
              background-color: skyblue;
              padding: 10px 10px 10px 10px;
              font-size: 16px;
              border: 0;
              border-radius: 15px;
            }</style>
            <button class="btn">跳转小程序</button>
          </script>
        </wx-open-launch-weapp>
      </div>
      <div class="_list">
        <img style="width: 100%" :src="activityData.details_img" alt="">
      </div>
      <div class="_list" v-if="activityData.jump_img!=''">
        <img style="width: 100%" :src="activityData.jump_img" @click=onClickPageLink(activityData.jump_link_url)>
      </div>
      <!--新增部分-->
      <div class="_list" v-if="resourceList.length>0">
        <div v-for="(item,index) of resourceList">
          <div class="image_title" :ref="'title'+index" :style="{'background-image':'url('+item.resources_img+')',}"
               @click="showImg(index)">展开
          </div>
          <div :ref="'img'+index" style="display: none">
            <img :src="item2.resources_img" style="width: 100%" v-for="item2 of item.children"/>
          </div>
        </div>
      </div>

      <div class="_divider">
        <van-divider :style="{ color: '#cbcbcb', borderColor: '#cbcbcb', padding: '0 16px', fontSize: '12px' }">
          活动说明
        </van-divider>
      </div>
      <div class="_textarea" v-html="activityData.activity_text.replace(/\n/g, '<br>')">
      </div>
    </div>
    <div class="_right_bar" @click="showTips = true" v-if="activityData">
      <img :src="activityData.rule_img">
    </div>
    <div class="_right_bar_new_card"
         @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')">
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png">
    </div>

    <van-overlay :show="showTips" :lock-scroll="false" @click="showTips = false" v-if="activityData">
      <div class="dialog-wrapper" @click.stop>
        <div class="_dialog_closed"><span class="_closed" @click="showTips = false"><van-icon name="clear"/></span>
        </div>
        <div class="_dialog_title">活动细则</div>
        <div class="_dialog_content" v-html="activityData.rule_text.replace(/\n/g, '<br>')">
        </div>
        <div class="_dialog_footer _only_btn">
          <van-button block round size="small" color="#fb967d" @click="showTips = false">知道了</van-button>
        </div>
      </div>
    </van-overlay>
    <!--    <div @click="onClickPageLink('#小程序://安逸酒店/TpchwoR7rAniX1w')" >adasdas</div>-->
    <div class="_bottom_fixed">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home?type='+activityData.card_id)"></span>
        <span class="_back_prev" @click="$router.go(-1)"></span>
      </div>
    </div>

    <div class="show_modal" v-if="show_modal">
      <p class="modal_title">升级公告</p>
      <p class="modal_content">ole/blt超市系统升级，活动暂停开展恢复时间另行通知</p>
      <div @click="show_modal=false" class="modal_btn_box">
        <div>确定</div>
      </div>
    </div>


  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";

export default {
  mixins: [page_links],
  components: {},
  data() {
    return {
      // currCard: 0,
      // currMall: 0,
      activityData: null,
      showTips: false,
      activityId: "",
      btnLinks: [],
      show_modal: false,
      resourceList: {}
    };
  },

  created() {
    console.log("-> [index.vue] created");
  },

  mounted() {
    console.log("-> [index.vue] mounted");
    this.activityId = this.$route.query.id;
    if (this.activityId == '7b5b5c90-58e8-4e22-b591-a280f913aae0') {
      this.show_modal = true;
    }
    this.getActivityData();
    document.documentElement.scrollTop = 0;
    this.getJsInfo();
  },

  methods: {
    showImg(id) {
      // const refsKeys = Object.keys(this.$refs);
      const myRefElement = this.$refs["title" + id];
      const images = this.$refs["img" + id];
      if (myRefElement[0].innerHTML === "收起") {
        images[0].style.display = "none"
        myRefElement[0].innerHTML = "展开"
      } else {
        images[0].style.display = "block"
        myRefElement[0].innerHTML = "收起"
      }
      // for (let i = 0; i <refsKeys.length; i++) {
      //   const myRefElement = this.$refs["title"+i];
      //   if(refsKeys[i]==="title"+id){
      //     myRefElement[0].innerHTML="收起"
      //     console.info('是',i)
      //   }else{
      //     myRefElement[0].innerHTML="展开"
      //     console.info('不是',i)
      //   }
      // }
    },
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        // data.res['openTagList']=['wx-open-launch-weapp'];
        // console.info(data.res);
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    async getResourceListByOtherId(id) {
      let {data} = await this.$ajax.gateway("/api/api_get_resource_by_other_id_group", {
        "METHOD": "get",
        "id": id
      });
      if (data.length > 0 && data[0].children.length > 0) {
        this.resourceList = data
      }

    },
    async getActivityData() {
      // 获取商户的详情
      let {data, code} = await this.$ajax.gateway("/api/api_get_merchant_detail", {
        "METHOD": "get",
        "id": this.activityId
      });
      if (code === 200) {
        if (data.activity_text == null) {
          data.activity_text = "";
        }
        if (data.rule_text == null) {
          data.rule_text = "";
        }
        // data.jump_link_url="gh_40bc99395c18"
        this.activityData = data;
        console.info(this.activityData);
        if (data.btn_link_cnt === 1) {
          this.btnLinks = [data.btn_link1 == '' ? "/shop_list?id=" + this.activityId : data.btn_link1 || ''];
        } else if (data.btn_link_cnt === 2) {
          this.btnLinks = [data.list_type != 0 && data.btn_link1 == "" ? "/shop_list?id=" + this.activityId : data.btn_link1 || '', data.btn_link2 || '', data.btn_link3 || ''];
          // this.btnLinks = [data.btn_link1 || '', data.btn_link2];
        } else if (data.btn_link_cnt === 3) {
          this.btnLinks = [data.list_type != 0 ? "/shop_list?id=" + this.activityId : data.btn_link1 || '', data.btn_link2 || '', data.btn_link3 || ''];
        }
      } else {
        this.$router.push("/");
      }
      this.getResourceListByOtherId(this.activityId);
    }
  }
};
</script>

<style lang="less" scoped>

.show_modal {
  background-color: white;
  border-radius: 0.5rem;
  width: 8rem;
  height: 5rem;
  position: fixed;
  top: 8rem;
  left: 0.9rem;
  border: 0.1rem solid;
  border-image: linear-gradient(to right, #e7d343, #f8a12b) 2;
  clip-path: inset(0 round 0.1rem);

  .modal_title {
    padding-top: 0.3rem;
    text-align: center;
    font-weight: bold;
    color: #f8a02b;
    font-size: 0.8rem;
  }

  .modal_content {
    padding: 0.3rem 0.3rem 0 0.3rem;
    color: #080303;
    font-size: 0.5rem;
    text-indent: 2em;
  }

  .modal_btn_box {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 0.5rem;
    position: relative;

    div {
      background: repeating-linear-gradient(to right, #e7d343, #f8a12b);
      padding: 0.2rem 0.2rem 0.2rem 0.2rem;
      border-radius: 0.2rem;
      text-align: center;
      width: 6rem;
      position: absolute;
      left: 0.7rem;
      color: #ffffff;
    }
  }
}

.activity-wrapper {
  min-height: 20rem;
  background: #f6f6f6;

  ._banner {
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    span._btn_link {
      display: inline-block;
      width: 3.6rem;
      height: 1rem;
      //border: 1px solid red;
      position: absolute;
    }

    &._banner_link_1 {
      span._btn_link_1 {
        width: 7.3rem;
        top: 5.3rem;
        left: 1.1rem;
      }
    }

    &._banner_link_2 {
      span._btn_link_1 {
        top: 5.2rem;
        left: 1.1rem;
      }

      span._btn_link_2 {
        top: 5.2rem;
        right: 1.1rem;
      }
    }

    &._banner_link_3 {
      span._btn_link_1 {
        top: 5.4rem;
        left: 1.1rem;
        height: 0.9rem;
      }

      span._btn_link_2 {
        top: 5.4rem;
        right: 1.1rem;
        height: 0.9rem;
      }

      span._btn_link_3 {
        width: 6.4rem;
        top: 6.6rem;
        left: 1.8rem;
        height: 1.1rem;
      }
    }
  }

  ._list {
    //margin: 0 0.6rem;
    //min-height: 10rem;
    position: relative;
    //background: #fff;
    //padding: 0.6rem 0.2rem 0.2rem;
    //border-radius: 0.3rem;
    //box-shadow: 0 6px 12px 0 rgba(248, 117, 89, 0.2);
    //._item {
    //  width: 50%;
    //  float: left;
    //  //margin-bottom: 0.2rem;
    //  img._pic {
    //    display: block;
    //    width: 100%;
    //  }
    //}
  }

  .image_title {
    background-repeat: no-repeat;
    line-height: 1.5rem;
    background-size: 100%;
    text-align: right;
    padding-right: 0.8rem;
    color: #ffffff;
    margin-bottom: -0.38rem;
    font-size: 14px;
  }

  ._textarea {
    padding: 0 1rem 0.6rem;
    font-size: 0.3rem;
    color: #afafaf;
    line-height: 1.6em;

    p {
      margin-bottom: 0.1rem;
    }
  }

  ._right_bar {
    position: fixed;
    top: 2.6rem;
    right: -0.2rem;

    img {
      height: 3.5rem;
    }

    //width: 0.6rem;
    //background: #fff;
    //padding: 0.25rem;
    //border-radius: 0.3rem;
    //color: #FF6952;
    //font-size: 0.22rem;
    //box-shadow: 0 6px 12px 0 rgba(248, 117, 89, 0.2);
    //line-height: 1.6em;
    //font-weight: 200;
    //span {
    //  display: inline-block;
    //  background: #f9d3d4;
    //}
  }

  ._right_bar_new_card {
    position: fixed;
    top: 0.3rem;
    right: 0rem;

    img {
      height: 2.7rem;
    }
  }
}

.dialog-wrapper {
  width: 80%;
  margin: 20% auto 0;
  background: #fff url("../status/img/dialog_top.png") no-repeat top left;
  background-size: 1.2rem auto;
  padding: 0.2rem 0.3rem 0.4rem;
  border-radius: 0.45rem;

  ._dialog_closed {
    text-align: right;
    color: #f56a4b;
    font-size: 0.45rem;
    margin-bottom: -0.2rem;
    position: relative;
    z-index: 1;
  }

  ._dialog_title {
    padding: 0 0.2rem 0.4rem;
    text-align: center;
    font-size: 0.45rem;
    color: #333;
  }

  // 修改高度
  ._dialog_content {
    max-height: 62vh;
    color: #333;
    font-size: 0.4rem;
    overflow: auto;
    line-height: 1.7em;
    padding: 0 0.2rem 0.3rem;

    p {
      margin-bottom: 0.3rem;
    }
  }

  ._dialog_footer {
    text-align: center;
    padding: 0.2rem 0;

    &._only_btn {
      padding: 0.1rem 1rem;
    }
  }
}
</style>
